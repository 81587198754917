













































































































































































































































































































































.well.non-image-file {
  padding: 0;
}
